import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import SearchJob from "../../components/SearchJob"
import { SectionTitle } from "../../components/styled"
import JobContent from "../../components/JobContent"
import { Seo } from "../../components/seo"
import useJobs from "../../hooks/useJobs"
import { languageData } from "../../translate/traduction"
const JobSearchTemplate = props => {
  const dataJobs = useJobs("English")
  const markProps = props.pageContext.slug
  const [markItem, setMarkItem] = useState([])
  useEffect(() => {
    dataJobs.forEach(element => {
      element.marks.forEach(elementMark => {
        if (elementMark.name === markProps) {
          setMarkItem(prevState => [...prevState, element])
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Seo
        title={`Search results: ${markProps} | Mexico job information, career change, and employment information [QUICK GLOBAL MEXICO]`}
      />
      <section className="container jobs-listing">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-9" style={{ padding: 0 }}>
            <div className="jobs-listing__search">
              <SectionTitle>
                Mexico Jobs, Employment and Career Change Information
              </SectionTitle>
              <hr className="u-line-bottom" />
              <div className="jobs-listing__job-search">
                <span>Search Criteria:</span>
                <Link to={`/en/job-search/${markProps}`}>
                  {languageData["en"].modal.conditions[markProps]}
                </Link>
                <p>
                  {markItem.length === 1
                    ? "There is " +
                      markItem.length +
                      " job that matches your search criteria"
                    : "There are " +
                      markItem.length +
                      " jobs that match your search criteria"}
                </p>
              </div>
              <div className="jobs-listing__search--box">
                <SearchJob lang={"en"} />
              </div>
            </div>
            <div className="jobs-listing__job-info">
              <hr className="u-line-bottom" />
              {markItem.map((jobs, i) => (
                <React.Fragment key={"jobContent_" + i}>
                  <JobContent jobs={jobs} lang={"en"} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JobSearchTemplate

export const query = graphql`
  query markSlugEn($slug: String) {
    allStrapiMarks(filter: { name: { eq: $slug } }) {
      nodes {
        name
      }
    }
  }
`
